import React from "react"
export default function KenDiagram() {
    return(
        <div className="ken-diagram">
            <div className="box">
            <p className="gis-p">GIS</p>
                <div className="green-box">
                    <p>2239</p>
                </div>
                <div className="blue-box">
                    <p>In SAP but missing in GIS <br/> 2345</p>
                </div>
                <div className="pink-box">
                    <p>Data integrity errors <br/>3456</p>
                </div>
                <p className="total-linked">Total linked 7890</p>
            <p className="sap-p">SAP</p>
            </div>
        </div>
    )
}