import React from 'react';
import logo from '../coct-logo@2x.png'

function Navigation({current}) {

    return (
        // <div className="header">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-4">
        //                 <img src={logo} alt="logo"  />
        //             </div>
        //             <div className="col-8 title">
        //                 <span className='title-big'>Department of Water and Sanitation</span>
        //                 <span className='title-small'>GIS/SAP Asset Comparison Tool (G/SACT)</span>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary header">
            <div className="container">
                <a className="navbar-brand" href="/"><img src={logo} alt="logo"  /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center w-100">
                        <li className="nav-item">
                            <a className={current === 'overview' ? "nav-link active" : "nav-link" } aria-current="page" href="/">Overview</a>
                        </li>
                        <li className="nav-item">
                            <a className={current === 'gis' ? "nav-link active" : "nav-link" } href="/gis">GIS View</a>
                        </li>
                        <li className="nav-item">
                            <a className={current === 'sap' ? "nav-link active" : "nav-link" } href="/sap">SAP View</a>
                        </li>
                    </ul>
                    <span className="navbar-text">
                    <span className='title-big'><strong>G/S ACT </strong>- GIS/SAP Asset Comparison Tool</span>
                    <span className='title-small'>CITY OF CAPE TOWN - DEPARTMENT OF WATER & SANITATION</span>
                    </span>
                </div>
            </div>
        </nav>
    )
}
export default Navigation;