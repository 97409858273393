import { Outlet } from "react-router-dom";


function Layout() {
    return(
        <div className="app">
            {/* <Navigation /> */}

            <Outlet />
            {/* <ToastContainer /> */}
            {/* <Footer />
            <Scrolltop />
            <LogoutModal /> */}
        </div>
    )
    }
    export default Layout;