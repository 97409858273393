import React from 'react';
import Navigation from '../components/Navigation';
import Filter from '../components/Filter';
import Table from '../components/Table';
import ErrorList from '../components/ErrorList';

export default function GIS() {
    return(
        <div>
            <Navigation current={"gis"} />
            <div className="container">
                <div className="row">
                    <Filter />
                    <Table />
                    <ErrorList />
                </div>
            </div>
        </div>
    )
}