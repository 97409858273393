import React, { useState } from 'react';
import axios from 'axios'
import Papa from 'papaparse';
import { values } from 'lodash';


const getData = async () => {
    try{
        const response = await fetch('https://docs.google.com/spreadsheets/d/1Vhhm-jfNRa2nTMfWFk4N6PQpVpO-Dz1p5lHZrPmPpaw/pub?gid=0&single=true&output=csv');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const csvText = await response.text();
        const parsedData = await parseCsv(csvText);
        return parsedData ? parsedData : []
    }
    catch (err) {
        console.error('Error fetching or parsing data:', err);
        return []
      }
  }
  const addFilter = (values) => {
    const { key_, value, filterData } = values;
    var data_ = filterData;
    console.log('filterData filterData', data_);
    if (data_.includes(value)) {
      data_ = data_.filter(result => result !== value); // Remove the value if it's already present
    } else {
      //data_.push(value); // Add the value if it's not present
    }
  
    filterData[key_] = data_; // Update filterData with the modified array
    console.log('filterData', filterData);
  
    return filterData;
  };
  const getAssetTypeTable = async (asset_type) =>{
    const results = await getData()
    const source_layer = await results.filter(result=> result['OBJECTID'] == asset_type)[0]['source_layer']
    for(let i=0; i<results.length; i++){
      return await results.filter(result=> result['source_layer'] == source_layer)
    }
    return []
  }
  const assetTypeFilter = data =>{
    const {value, assetData} = data
    var new_data = [];
    for(let i =0; i<assetData.length; i++){
      new_data.push(assetData[i])
    }
    if (assetData.includes(value)) {
      new_data = new_data.filter(result => result !== value); // Remove the value if it's already present
    } else {
      new_data.push(value); // Add the value if it's not present
    }
    console.log('new data', new_data)
    return new_data
  }

  const errorTypeFilter = data =>{
    const {value, errorTypeData} = data
    var new_data = [];
    for(let i =0; i<errorTypeData.length; i++){
      new_data.push(errorTypeData[i])
    }
    if (errorTypeData.includes(value)) {
      new_data = new_data.filter(result => result !== value); // Remove the value if it's already present
    } else {
      new_data.push(value); // Add the value if it's not present
    }
    console.log('error', new_data)
    return new_data
  }

  const syncFilter = data =>{
    const {value, filterSync} = data
    var new_data = [];
    for(let i =0; i<filterSync.length; i++){
      new_data.push(filterSync[i])
    }
    if (filterSync.includes(value)) {
      new_data = new_data.filter(result => result !== value); // Remove the value if it's already present
    } else {
      new_data.push(value); // Add the value if it's not present
    }
    console.log('filterSync', new_data)
    return new_data
  }

  const getAssetType = async (filterErrorType)=>{
    const results = await getData()
    var asset_types = []
    var asset_types_dist = []
    for(let i=0; i<results.length; i++){
        if(!asset_types_dist.includes(results[i]['source_layer']) ){
            const checkTrue = results.filter(result=> result['source_layer'] == results[i]['source_layer'] && result['sap_obj_type_match'] == 'TRUE' )
            const checkFalse = results.filter(result=> result['source_layer'] == results[i]['source_layer'] && result['sap_obj_type_match'] == 'FALSE' )
            asset_types.push({
                'type':results[i]['source_layer'],
                'false':checkFalse.length,
                'true':checkTrue.length,
                'objectId':results[i]['OBJECTID'],
                summary_concat : results[i]['Summary Concat'],
                lu_sync : results[i]['LU_SYNC']
            })
            asset_types_dist.push(results[i]['source_layer'])
        }
    }
    console.log('service')
    return asset_types
  }

const getErrorList = async ()=>{
  const results = await getData()
  var no_record = 0
  var mismatch = 0
  var sap_floc_missing_gi = 0
  for(let i =0; i<results.length; i++){
    no_record = results[i]['No corresponding record in SAP'] ? no_record + 1 : no_record
    mismatch = results[i]['Tech Object Type mismatch'] ? mismatch + 1 : mismatch
    sap_floc_missing_gi = results[i]['SAP Floc missing in GIS'] ? sap_floc_missing_gi + 1 : sap_floc_missing_gi
  }
  return{
    'no_record':no_record,
    'mismatch':mismatch,
    'sap_floc_missing_gi':sap_floc_missing_gi
  }
}

  const parseCsv = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
            console.log('result', result.data)
          resolve(result.data);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  };

  const dataService = {
    getData,
    getAssetType,
    getErrorList,
    getAssetTypeTable,
    assetTypeFilter,
    addFilter,
    errorTypeFilter,
    syncFilter
}

export default dataService