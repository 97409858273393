import React, { useEffect, useState } from 'react';
import OverviewCard from '../components/OverviewCard';
import Navigation from '../components/Navigation';
import KenDiagram from '../components/KenDiagram';

export default function OverviewStats() {
    const [totalErrors, setTotalErrors] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [percentageErrors, setPercentageErrors] = useState(0)
    return (
        <>
        <Navigation current={"overview"} />
        <div className="overview container">
            <div className="row">
                <div className="col-12">
                    <OverviewCard date={'03/10/2024'} totalErrors={451} totalRecords={1861} percentageErrors={24.2} title={"GIS View"} subtitle={"(SAP Assets Missing from GIS System)"} />
                    <OverviewCard date={'29/09/2024'} totalErrors={302} totalRecords={1663} percentageErrors={18.1} title={"SAP View"} subtitle={"(GIS Assets Missing from SAP System)"} />
                    <KenDiagram />
                </div>
            </div>
        </div>
        </>
    )
}