import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import _ from 'lodash';
import './app.scss';

import Layout from './components/Layout'
import GIS from './pages/GIS';
import Asset from './pages/Asset';
import OverviewStats from './pages/OverviewStats';
import SAP from './pages/SAP';

function App() {

  return(
      <Provider store={store}>
      <Router>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route path='/' element={<OverviewStats />} /> 
                  <Route path='/gis' element={<GIS />} />
                  <Route path='/sap' element={<SAP />} />
                  <Route path='/gis_asset/:id' element={<Asset />} />
              </Route>
          </Routes>
      </Router>
      </Provider>
  )
}

export default App;
