import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dataService from './dataService'


const initialState = {
  filteredData:{
    assetType: [],
    errorType: [],
    syncStatus:[]
  },
  filterDataAsset : [],
  filterErrorType : [],
  filterSync : [],
  allData:[],
  asset_type:[],
  assetTypeTable:[],
  errorList:{'no_record':0, 'mismatch':0, 'sap_floc_missing_gi':0},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const addFilter = createAsyncThunk(
  'get/addfilter',
  async (data,thunkAPI) => {
    try {
      return await dataService.addFilter(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
        console.log('error', message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const assetTypeFilter = createAsyncThunk(
  'get/assetTypeFilter',
  async (data,thunkAPI) => {
    try {
      return await dataService.assetTypeFilter(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
        console.log('error', message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const syncFilter = createAsyncThunk(
  'get/syncFilter',
  async (data,thunkAPI) => {
    try {
      return await dataService.syncFilter(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
        console.log('error', message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const errorTypeFilter = createAsyncThunk(
  'get/errorTypeFilter',
  async (data,thunkAPI) => {
    try {
      return await dataService.errorTypeFilter(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
        console.log('error', message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAssetType = createAsyncThunk(
    'get/AssetType',
    async (_,thunkAPI) => {
      try {
        return await dataService.getAssetType()
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
          console.log('message',message)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getAssetTypeTable = createAsyncThunk(
    'get/getAssetTypeTable',
    async (asset,thunkAPI) => {
      try {
        return await dataService.getAssetTypeTable(asset)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getErrorList = createAsyncThunk(
    'get/getErrorList',
    async (_,thunkAPI) => {
      try {
        return await dataService.getErrorList()
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(getAssetType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssetType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.asset_type = action.payload
      })
      .addCase(getAssetType.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.asset_type = []
    })
    .addCase(getErrorList.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getErrorList.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.errorList = action.payload
    })
    .addCase(getErrorList.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.errorList = {'no_record':0, 'mismatch':0, 'sap_floc_missing_gi':0}
  })
  .addCase(getAssetTypeTable.fulfilled, (state, action) => {
    state.assetTypeTable = action.payload
  })
  .addCase(addFilter.fulfilled, (state, action) => {
    state.filteredData = action.payload
  })
  .addCase(assetTypeFilter.fulfilled, (state, action) => {
    state.filterDataAsset = action.payload
  })
  .addCase(errorTypeFilter.fulfilled, (state, action) => {
    state.filterErrorType = action.payload
  })
  .addCase(syncFilter.fulfilled, (state, action) => {
    state.filterSync = action.payload
  })

},
})

export const { reset } = dataSlice.actions
export default dataSlice.reducer