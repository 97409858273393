import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getErrorList, reset } from '../features/data/dataSlice'

export default function ErrorList() {
    const dispatch = useDispatch()
    const { errorList, filterErrorType, isError, isSuccess, message } = useSelector(
        (state) => state.data
    )

    useEffect(() => {
        dispatch(getErrorList())
    }, [])

    return (
        <div className="col-2" style={{ marginLeft: '5px' }}>
            <div className="row ">
                <div className="col board" style={{ padding: '33px 20px' }}>
                    <p>Notes</p>
                </div>
            </div>
            <div className="row table-row mt-4 mb-4" >
                <div className="col-12 bg-blue ">
                    <h3>Error List</h3>
                </div>
                <table class="table top-border-blue table-bordered">
                    <thead className='bg-blue'>
                        <tr className='bg-blue'>
                            <th className='bg-blue' scope="col"></th>
                            <th className='bg-blue' scope="col">Assets</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !filterErrorType.includes('SAP Floc missing in GIS') ?
                                <tr>
                                    <th scope="row">SAP Floc missing in GIS</th>
                                    <td>{errorList.sap_floc_missing_gi}</td>
                                </tr> : ""
                        }
                        {
                            !filterErrorType.includes('No record in SAP') ?
                                <tr>
                                    <th scope="row">No record in SAP</th>
                                    <td>{errorList.no_record}</td>
                                </tr> : ""
                        }
                        {
                            !filterErrorType.includes('Object Type mismatch') ?
                                <tr>
                                    <th scope="row">Object Type mismatch</th>
                                    <td>{errorList.mismatch}</td>
                                </tr> : ""
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}