import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getAssetType,getAssetTypeTable, reset } from '../features/data/dataSlice'
import BarChart from './BarChart';
import AssetModal from './AssetModal';



export default function Table(){
    const [totalErrors, setTotalErrors] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [percentageErrors, setPercentageErrors] = useState(0)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { asset_type, filterSync, filterErrorType , filterDataAsset, assetTypeTable } = useSelector(
        (state) => state.data
    )
    useEffect(() => {
        dispatch(getAssetType())
    },[])

    useEffect(()=>{
        console.log('assetTypeTable', assetTypeTable)
    },[assetTypeTable])

    useEffect(() => {
        dispatch(getAssetType())
    },[filterDataAsset, filterErrorType])

    useEffect(() => {
        var err = 0
        var rec = 0
        for(let i =0; i< asset_type.length; i++){
            if(filterDataAsset.length > 0 || filterErrorType.length > 0 || filterSync.length > 0 ){
                var item = asset_type[i]
                if(filterDataAsset.includes(item.type) || filterErrorType.includes(item.summary_concat) || filterSync.includes(item.lu_sync) ){
                    err += asset_type[i].false
                    rec = rec + asset_type[i].true + asset_type[i].false
                }
            }
            else{
                err += asset_type[i].false
                rec = rec + asset_type[i].true + asset_type[i].false
            }
        }
        console.log('err', err)
        setTotalErrors(err)
        setTotalRecords(rec)
        setPercentageErrors((err / rec * 100).toFixed(1))
    },[asset_type, filterDataAsset, filterErrorType])

    const getAssetTypeName = name =>{
        switch(name){
            case "SL_SWR_PUMP_STN_SYNC":
                return "Sewer - Pump Stations"
            case "SL_SWR_TRMN_PLNT_SYNC":
                return "Sewer - Treatment Plants"
            case "SL_WTNK_BLD_DPTS_WRKS_SYNC":
                return "WS Buildings - Depots and Workshops"
            case "SL_WTNK_BLD_WS_STAFF_HSNG_SYNC":
                return "WS Buildings - Staff Housing"
            case "SL_WTNK_BULK_BRHL_SYNC":
                return "Bulk Water - Boreholes"
            case "SL_WTNK_BULK_DAMS_SYNC":
                return "Bulk Water - Dams"
            case "SL_WTNK_BULK_PUMP_STN_SYNC":
                return "Bulk Water - Pump Stations"
            case "SL_WTNK_BULK_RSRV_SYNC":
                return "Bulk Water - Reservoirs"
            case "SL_WTNK_BULK_TRTM_PLNT_SYNC":
                return "Bulk Water - Treatment Plants"
            case "SL_WTNK_SPR_RSRV_SYNC":
                return "Spring Water - Reservoir"
            case "SL_WTNK_TE_PUMP_STN_SYNC":
                return "Treated Effluent - Pump Stations"
            case "SL_WTNK_TE_RSRV_SYNC":
                return "Treated Effluent - Reservoirs"
            case "SL_WTR_PUMP_STN_SYNC":
                return "Water - Pump Stations"
            case "SL_WTR_ZONE_MNG_STN_SYNC":
                return "Water - Zone Management Stations"
            case "SL_WTR_RSRV_SYNC":
                return "Water - Reservoirs"
        }
    }
    return(
        <div className="col-6">
            <div className="row ">
                <div className="col board" >
                    <h3>{totalErrors}</h3>
                    <label>Total Error</label>
                </div>
                <div className="col board">
                <h3>{totalRecords}</h3>
                <label>Total Records</label>
                </div>
                <div className="col board">
                <h3>{percentageErrors}%</h3>
                <label>Percentage Errors</label>
                </div>
            </div>
            <div className="row table-row mt-4 mb-4" >
                <div className="col-12 bg-blue ">
                    <h3>Records - By Asset Type</h3>
                </div>
                    <table class="table top-border-blue table-bordered">
                        <thead className='bg-blue'>
                            <tr className='bg-blue'>
                                <th className='bg-blue' scope="col">Sources</th>
                                <th className='bg-blue' scope="col">False</th>
                                <th className='bg-blue' scope="col">True</th>
                                <th className='bg-blue' scope="col">Total</th>
                                <th className='bg-blue' scope="col">% of Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filterErrorType && filterDataAsset && asset_type.map((item, index)=>{

                                    if(filterDataAsset.length > 0 || filterErrorType.length > 0 || filterSync.length > 0 ){
                                        if(filterDataAsset.includes(item.type) || filterErrorType.includes(item.summary_concat) || filterSync.includes(item.lu_sync) ){
                                            return(
                                                <tr key={index}>
                                                    <th scope="row" className='d-flex'><a style={{textAlign:"left"}} href={'/gis_asset/'+ item.objectId}>{getAssetTypeName(item.type)}</a></th>
                                                    <td style={{color:"red", fontWeight:"500"}}>{item.false}</td>
                                                    <td>{item.true}</td>
                                                    <td>{item.false + item.true}</td>
                                                    <td>{(item.false / (item.false + item.true) * 100).toFixed(1)}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                    else{
                                        return(
                                            <tr key={index}>
                                                <th scope="row" className='d-flex'><a style={{textAlign:"left"}}  href={'/gis_asset/'+ item.objectId}>{getAssetTypeName(item.type)}</a></th>
                                                <td style={{color:"red", fontWeight:"500"}}>{item.false}</td>
                                                <td>{item.true}</td>
                                                <td>{item.false + item.true}</td>
                                                <td>{(item.false / (item.false + item.true) * 100).toFixed(1)}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                            <tr>
                                                <th scope="row" className='d-flex justify-content-end' style={{fontWeight:"600"}}>Total</th>
                                                <td style={{color:"red", fontWeight:"500"}}>{totalErrors}</td>
                                                <td>{totalRecords - totalErrors}</td>
                                                <td>{totalRecords}</td>
                                                <td>{(totalErrors / (totalRecords) * 100).toFixed(1)}</td>
                                            </tr>
                        </tbody>
                    </table>
                
                <div className="col-12 mt-4 mb-4">
                    <h3>Breakdown of of Records: True or False</h3>
                    <BarChart asset_type={asset_type} filterErrorType={filterErrorType} filterSync={filterSync} filterDataAsset={filterDataAsset} getAssetTypeName={getAssetTypeName} />
                </div>
            </div>
            {/* <AssetModal show={assetTypeTable.length >  0 && show} handleClose={handleClose} /> */}
        </div>
    )
}